.App {
  text-align: center;
  font-family: 'Eczar', serif;
}

.App-title-card {
  margin-top: 2rem;
  height: 25vh;
}

h1 {
  font-family: 'Diplomata', cursive;
  color: #2b2929;
}

hr {
  width: 50%;
  margin-top: 2rem;
  border: 1.5px solid rgb(191, 178, 77);
}
