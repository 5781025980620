.info_form {
    display: grid;
    grid-template: repeat(7, 1fr) / 1fr 15px 1fr;
}

#submit-button {
    color: #2b2929;
    font-family: 'Eczar', serif;
    font-size: 1.3rem;
    background-color: rgb(255, 254, 252);
    border: 3px solid rgb(191, 178, 77);
    border-radius: 10px;
    width: 250px;
    justify-self: center;
    padding: 10px 10px;
    margin: 20px;
    grid-area: 6 / 1 / 8 / 4;
}

.dropdown {
    width: 90px;
    align-self: center;
    padding: 3px;
    font-size: 1rem;
    color: rgb(70, 70, 70);
    border-radius: 5px;
    border: 1px solid rgb(191, 178, 77);
    background-color: rgb(255, 254, 252);
}

label::before {
    content: "\27a0";
    color: rgb(191, 178, 77);
    font-size: 1.5rem;
    margin-right: .5rem;

}

label {
    font-size: 1.2rem;
    text-align: right;
    align-self: center;
}

#first_label {
    grid-area: 1 / 1 / 2 / 2;
}

#first {
    grid-area: 1 / 3 / 2 / 4;
}

#middle_label {
    grid-area: 2 / 1 / 3 / 2;
}

#middle {
    grid-area: 2 / 3 / 3 / 4;
}

#last_label {
    grid-area: 3 / 1 / 4 / 2;
}

#last {
    grid-area: 3 / 3 / 4 / 4;
}

#month_label {
    grid-area: 4 / 1 / 5 / 2;
}

#month {
    grid-area: 4 / 3 / 5 / 4;
}

#day_label {
    grid-area: 5 / 1 / 6 / 2;
}

#date {
    grid-area: 5 / 3 / 6 / 4;
}